export default {translation: {
  "tytul": "Qualified Seal",
  "o-nas": "Why Choose Us",
  "nasze-marki": "Our Products",
  "historia": "History",
  "voucher": "Voucher",
  "kontakt": "Contact",
  "tekst-gora": "Secure your digital documents! Use a qualified seal - an essential tool for verifying the authenticity and integrity of electronic documents. Protection against forgery and alterations in documents. Facilitates the registration of documents in KSeF (National Electronic Invoice Registry System).",
  "sprawdz": "Check it now! ",
  "pieczatka": "Electronic Seal ",
  "w-bydgoszczy": "Qualified seal with identity verification in Bydgoszcz",
  "bezpieczne-niezawodne": "Safe and reliable. Qualified seal for signing documents",
  "co-to": "What is an electronic seal? ",
  "co-to-tekst": "It is a qualified trust service issued for legal entities (e.g., companies, institutions, public entities). It allows the signing of any documents and files using only company data. ",
  "co-daje": "What can an electronic seal give you? ",
  "co-daje-lista-1": "Credibility of the document's origin – the source of the document, the ability to identify the entity that issued the document, ",
  "co-daje-lista-2": "Document integrity – guarantee of data immutability since sealing, ",
  "co-daje-lista-3": "Protection of personal data - the seal contains only the entity's data (name and tax identification number), ",
  "co-daje-lista-4": "Faster document circulation - enables process automation and document tagging, providing the opportunity to connect with document issuing systems. ",
  "gdzie-uzyc": "Possible uses of a qualified electronic seal ",
  "gdzie-uzyc-lista-1": "Issuing official certificates, ",
  "gdzie-uzyc-lista-2": "Corporate electronic correspondence, ",
  "gdzie-uzyc-lista-3": "Legal documents, ",
  "gdzie-uzyc-lista-4": "Documents related to invitations to submit offers, tender proceedings, ",
  "gdzie-uzyc-lista-5": "Electronic invoices, ",
  "gdzie-uzyc-lista-6": "Confirmation of online service transaction. ",
  "nasze-produkty": "OUR PRODUCTS ",
  "card-1": "Electronic Seal",
  "card-2": "Electronic Seal HSM",
  "card-3": "Electronic Seal PSD2",
  "umow-wizyte": "Schedule an Appointment ",

  "pieczec-elektroniczna-tytul": "Electronic Seal",
  "pieczec-elektroniczna-desc1": "Qualified EuroCert certificate - valid for 2 years",
  "pieczec-elektroniczna-desc2": "Cryptographic card or card+reader ",
  "pieczec-elektroniczna-desc3": "Software for creating and verifying electronic seals ",
  "pieczec-elektroniczna-desc4": "Entity data verification service ",
  "pieczec-elektroniczna-desc5": "Remote technical support. ",
  "pieczec-elektroniczna-cena": "From: 1,597.77 PLN (net: 1,299.00 PLN) ",
  "pieczec-elektroniczna-opis": "An electronic seal is a qualified trust service issued to legal entities (e.g., companies, institutions, public entities). It allows the sealing of any documents and files using only company data. ",

  "pieczec-elektroniczna-hsm-tytul": "Electronic Seal HSM ",
  "pieczec-elektroniczna-hsm-desc1": "Qualified EuroCert certificate - valid for 2 years, ",
  "pieczec-elektroniczna-hsm-desc2": "Software for creating an electronic seal, ",
  "pieczec-elektroniczna-hsm-desc3": "Entity data verification service, ",
  "pieczec-elektroniczna-hsm-desc4": "Remote technical support. ",
  "pieczec-elektroniczna-hsm-desc5": "null",
  "pieczec-elektroniczna-hsm-cena": "Want to learn more? Contact us!",
  "pieczec-elektroniczna-hsm-opis": "Electronic Seal HSM - a qualified trust service issued to legal entities (e.g., companies, institutions, public entities). It allows the sealing of any documents and files using only company data. The seal is stored on a secure Hardware Security Module (HSM). The HSM can be located at the client's premises or within the EuroCert infrastructure. ",

  "pieczec-elektroniczna-psd2-tytul": "Electronic Seal PSD2 ",
  "pieczec-elektroniczna-psd2-desc1": "Qualified electronic seal (QSEAL PSD2) ",
  "pieczec-elektroniczna-psd2-desc2": "Qualified certificate for authenticating websites (QWAC PSD2). These allow meeting the requirements and regulations arising from the PSD2 directive, imposed on payment service providers operating in the European Union, including account-servicing payment service providers (ASPSP) and external payment service providers (TPP). ",
  "pieczec-elektroniczna-psd2-desc3": "Authenticity, confidentiality, and integrity of communication, ",
  "pieczec-elektroniczna-psd2-desc4": "Legally binding evidence of transactions and content. ",
  "pieczec-elektroniczna-psd2-desc5": "null",
  "pieczec-elektroniczna-psd2-cena": "The 'Electronic Seal PSD2' product does not include the QWAC PSD2 website authentication certificate.",
  "pieczec-elektroniczna-psd2-opis": `
  <h2 class="blue">What certificates does a payment service provider need according to the PSD2 directive?</h2>
  <br />
  The entities mentioned are obliged to have the following qualified certificates in their company:
  <ul>
    <li><b>Qualified certificate for authenticating websites (QWAC PSD2)</b> - which allows verifying the entity's identity, encrypting and authenticating data transmitted in peer-to-peer communication.
    </li>
    <li><b>Qualified electronic seal (QSEAL PSD2)</b> - for digital signing of transmitted data and ensuring their integrity and authenticity. The seal certificate contains information about the entity and its role, including:
    <ul>
      <li>Account-servicing payment service provider (ASPSP),</li>
      <li>Account information service provider (AISP),</li>
      <li>Payment initiation service provider (PISP),</li>
      <li>Payment instrument issuer service provider (PIISP).</li>
    </ul>
    </li>
  </ul>
  <br />
  If you are interested in purchasing a set of PSD2 certificates, please contact us!
  <h2 class="blue">Depending on the chosen product, you can purchase:</h2>
  <ul>
    <li>Qualified electronic seal certificate QSEAL PSD2
    </li>
    <li>Qualified website authentication certificate QWAC PSD2
    </li>
    <li>Test certificates QWAC PSD2 and QSEAL PSD2
    </li>
  </ul>
  <h2 class="blue">PSD2 certificates are intended for:</h2>
  <ul>
    <li>Banks,</li>
    <li>Payment institutions,</li>
    <li>Independent ATM operators,</li>
    <li>Non-bank payment service providers,</li>
    <li>Distributors of store and fuel cards,</li>
  </ul>
  `,

  "dodatkowa-usluga": "Get additional security:",
  "dodatkowa-usluga-opis": "A package of qualified timestamps - an additional service that allows confirming the creation or signing of a document on a specific day and time. It protects the file from forgery or backdating. The time by which the document is timestamped does not come from the system time (workstation or server) but is derived from an independent source, such as a Trusted Third Party."
}
}
